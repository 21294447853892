
@import "../../../../autoload/compatibility";

$tstpRadius: 20px;
$tstpBorderColor: rgba(255,255,255,0.75);
#thomaxScrollToTop{
	position: fixed;
	bottom: 20px;
	right: 20px;
	width: $tstpRadius*2;
	height: $tstpRadius*2;
	line-height: $tstpRadius*2;



	-webkit-border-radius: $tstpRadius;
	-moz-border-radius: $tstpRadius;
	border-radius: $tstpRadius;


	color: #fff;

	opacity: 0.75;

	z-index: 200;

	font-size: 18px;
	background-color: $io-blue-light;
	@include transform(translateX(100px));
	@include transition(all 350ms ease);

	text-align: center;
	cursor: pointer;
	@media (max-width: $screen-xs-max){
		$tstpRadius: 30px;
		width: $tstpRadius*2;
		height: $tstpRadius*2;
		line-height: $tstpRadius*2;
		-webkit-border-radius: $tstpRadius;
		-moz-border-radius: $tstpRadius;
		border-radius: $tstpRadius;
		font-size: 25px;
	}
	.icon{
		line-height: $tstpRadius*2-5;
		@media (max-width: $screen-xs-max){
			$tstpRadius: 30px;
			line-height: $tstpRadius*2-5;
		}
	}

  	&:hover{
		opacity: 1;
		-webkit-box-shadow: 0 0 0 2px $tstpBorderColor;
		-moz-box-shadow: 0 0 0 2px $tstpBorderColor;
		box-shadow: 0 0 0 2px $tstpBorderColor;
  	}

}
